<template>
  <div class="column-round column-round-small first last" id="newsNetworkStoryPrint">
    <b><i></i></b><i><b></b></i><em></em>
    <div class="column-round-body">
      <p>
        <router-link :to="sendToFriend" class="linkArrow popup">
          <span id="ControlArticleInfoBox1_DictionaryText4">Send to a friend</span>
        </router-link>
        <!-- window.open('PrinterFriendly.aspx?id=318012','','height=720,width=620,status=no,resizable=no,scrollbars=yes,toolbar=no,location=no,menubar=no'); -->
        <router-link :to="printerFriendly" class="linkArrow">
          <span id="ControlArticleInfoBox1_DictionaryTextPrint">Print this page</span>
        </router-link>
      </p>
      <div class="hr"></div>
      <p id="newsNetworkStoryPrintDate">
        <span id="ControlArticleInfoBox1_DictionaryText1">Posted</span>
        &nbsp;{{story.date}}&nbsp;
        <span v-if="story.category">
          <span id="ControlArticleInfoBox1_DictionaryText2">in</span>
          &nbsp;
          <router-link :to="story.category.href">{{story.category.title}}</router-link>
        </span>
        &nbsp;
        <span id="ControlArticleInfoBox1_DictionaryText3">by</span> {{story.author}}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["story", "id"],
    computed: {
      sendToFriend() {
        const id = this.id;
        return `/community/news-network/${id}/send-to-friend`
      },
      printerFriendly() {
        const id = this.id;
        return `/community/news-network/${id}/printer-friendly`
      }
    }
  }
</script>


export default {
  data() {
    return {};
  },
  methods: {
    prevPage() {
      console.warn("TODO: implement Comments::prevPage");
    },
    nextPage() {
      console.warn("TODO: implement Comments::nextPage");
    },
  }
}

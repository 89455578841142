<template>
  <div class="column-row column-row-3" style="margin-bottom: 80px; height: 51px">
    <div class="column column-large first last">
      <div class="column-top">
        <h1>Comments</h1>
      </div>
      <div class="column-content">
        <div id="Comments">
          <input id="CommentsType" type="hidden" value="NewsNetwork" />
          <input id="Language" type="hidden" value="2057" />
          <div id="CommentsPost" class="negative-left">
            <div class="column-round column-round-small first">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                <div id="Comments_PanelLoggedOut">
                  You need to sign in to post a comment
                </div>
              </div>
            </div>
          </div>

          <div id="CommentsSection">
            <p class="column-navigation">
              ­
              <a :click="prevPage" class="play-left disabled" id="CommentsPrev">«</a>
              <span id="CommentsPageNumber">1</span>
              of
              <span id="CommentsPageCount">1</span>
              <a :click="nextPage" class="play-right disabled" id="CommentsNext">»</a>
              <label for="CommentsShow">Show:</label>
              <select id="CommentsShow" name="CommentsShow">
                <option value="5" selected="true">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <label for="CommentsOrder">Order:</label>
              <select id="CommentsOrder" name="CommentsOrder">
                <option value="latest" selected="true">Most Recent</option>
                <option value="oldest">Oldest</option>
                <option value="rating">Rating</option>
              </select>
            </p>
            <div class="hr"></div>

            <div id="CommentsHolder"></div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {};
  },
  methods: {
    prevPage() {
      console.warn("TODO: implement Comments::prevPage");
    },
    nextPage() {
      console.warn("TODO: implement Comments::nextPage");
    },
  }
}
</script>


<style lang="scss">
#CommentsPost {
  float: right;
  margin-bottom: 18px;
}

#CommentsSection {
  float: left;
  width: 470px;
}
</style>

<template>
  <div id="mainColumn1">
    <div :class="styleClass" :style="style">
      <div class="column column-large first last">
        <div class="column-top">
          <h1>{{story.title}}</h1>
        </div>
        <div class="column-content" style="min-height: 200px;">
          <story-print :story="story" :id="id"></story-print>
          <component id="newsStoryContent" v-if="componentInstance" :is="componentInstance"></component>
        </div>
      </div>
    </div>
    <div v-if="story.extra" :class="extraStyleClass" :style="extraStyle">
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content" style="min-height: 200px;">
          <component :is="story.extra" v-if="story.extra"></component>
        </div>
      </div>
    </div>
    <comments></comments>
  </div>
</template>

<script>
import { STORIES } from '@/stories/index.ts';
import StoryPrint from '@/components/news-network/StoryPrint.vue';
import Comments from '@/components/news-network/Comments.vue';
import Missing from '@/components/modules/Missing.vue';

export default {
  computed: {
    componentInstance () {
      if (this.story && this.story.component) {
        return this.story.component;
      }
      return Missing;
    },
    id() {
      return this.$route.params.id;
    },
    story() {
      return STORIES[this.id];
    },
    style() {
      var style = {};
      style["margin-bottom"] = "80px";
      if (this.story.hasOwnProperty('height')) {
        style["height"] = `${this.story.height}px`;
      }
      return style;
    },
    extraStyle() {
      var style = {};
      style["margin-bottom"] = "80px";
    },
    styleClass() {
      var cls = ["column-row"];
      if (this.story.hasOwnProperty('long') && this.story.long) {
        cls.push("column-row-1-2");
      } else {
        cls.push("column-row-3");
      }
      return cls;
    },
    extraStyleClass() {
      var cls = ["column-row"];
      if (this.story.hasOwnProperty('extraLong') && this.story.extraLong) {
        cls.push("column-row-1-2");
      } else {
        cls.push("column-row-3");
      }
      return cls;
    }
  },
  async asyncData(context) {
    return {
      id: this.$route.params.id
    }
  },
  components: {
    StoryPrint,
    Comments,
  }
}
</script>

<style lang="scss">
  #newsStoryContent {
    width: 470px;

    .center {
      text-align: center;
    }

    .leftimage {
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .rightimage {
      float: right;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    center {
      margin-bottom: 10px;
    }

    video {
      cursor: pointer;
    }

    img, video {
      background: #000;
      display: inline-block;
      width: 440px;
    }

    img {
      height: 261px;

      &.sq-137 {
        width: 137px;
        height: 137px;
      }

      &.x-auto {
        width: initial;
        height: initial;
      }

      &.r-2 {
        border-radius: 5px;
      }

      &.img-large {
        width: 440px;
        height: 330px;
      }

      &.img-tiny {
        width: 133px;
        height: 100px;
      }

      &.img-xxs {
        margin-top: 4px;
        margin-bottom: 12px;
        width: 100px;
        height: 75px;
      }

      &.img-small {
        width: 200px;
        height: 150px;
      }

      &.img-med {
        width: 200px;
        height: 185px;
      }

      &.w-100 { width: 100px; }
      &.w-200 { width: 200px; }
      &.w-220 { width: 220px; }
      &.w-680 { width: 680px; }
      &.h-75 { height: 75px; }
      &.h-125 { height: 125px; }
      &.h-130 { height: 130px; }
      &.h-165 { height: 165px; }
      &.h-215 { height: 215px; }
      &.h-250 { height: 250px; }
      &.h-258 { height: 258px; }
      &.h-267 { height: 267px; }
      &.h-265 { height: 265px; }
      &.h-336 { height: 336px; }
      &.h-340 { height: 340px; }
      &.h-490 { height: 490px; }
    }
  }

  #newsNetworkStoryPrint {
    float: right;
  }

  #newsNetworkStoryPrintDate {
    font-size: .9em;
  }
</style>
